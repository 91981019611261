export const HOME_URL = "https://airrobe.com";

export const SITE_FOR_BRANDS_URL = `${HOME_URL}/airrobe-for-brands`;
export const SITE_SHOP_PATH = "/";

export const ADMIN_PATH = "/admin";
export const LOGIN_PATH = "/login";
export const SIGNUP_PATH = "/signup";
export const LOGOUT_PATH = "/logout";
export const CHECKOUT_PATH = "/checkout";
export const FORGOT_PASSWORD_PATH = "/forgot_password";

export const NAV_BRANDS_PATH = "/brands";
export const NAV_WOMEN_PATH = "/womens";
export const NAV_MEN_PATH = "/mens";
export const NAV_KIDS_PATH = "/kids";
export const NAV_SHOP_ALL_PATH = "/s";
export const NAV_DESIGNER_PATH = "/designer";

export const ADD_AN_ITEM_PATH = "/products/new";

export const HOME_ABOUT_URL = `${HOME_URL}/airrobe`;
export const HOME_CIRCULAR_WARDROBE_URL = `${HOME_URL}/circular-wardrobe`;
export const HOME_PARTNER_BRANDS_URL = `${HOME_URL}/partner-brands`;
export const HOME_HOW_TO_SELL_URL = `${HOME_URL}/how-to-sell`;
export const HOME_HOW_TO_BUY_URL = `${HOME_URL}/how-to-buy`;
export const HOME_PRIVACY_POLICY_URL = `${HOME_URL}/privacy-policy`;
export const HOME_TERMS_CONDITIONS_URL = `${HOME_URL}/terms-conditions`;

export const SUPPORT_URL = "https://airrobe.zendesk.com/hc/en-us";
export const SUPPORT_CONTACT_URL = "mailto:support@airrobe.zendesk.com?subject=Customer%20Help";

export const SOCIALS_INSTAGRAM_URL = "https://www.instagram.com/airrobe";

export const USER_PROFILE_PATH = "";
export const USER_PROFILE_SORT_PATH = "/sort_listings";
export const USER_INBOX_PATH = "/inbox";
export const USER_FAVOURITES_PATH = "/favourites";
export const USER_LISTINGS_PATH = "/listings";
export const USER_SETTINGS_PATH = "/settings";
export const USER_TRANSACTIONS_PATH = "/settings/transactions";
export const USER_ACCOUNT_PATH = "/settings/account";
export const USER_PAYMENTS_PATH = "/settings/payments";

export const HISTORICAL_ORDERS_PATH = "/historical_orders";

export const CONCIERGE_LISTINGS_PATH = "/concierge";

export const getBrandPathByKeyOrName = (brand_key: string, brand: string): string => {
  if (typeof brand_key === "undefined") return `/s?q=${brand}`;
  return `/b/${brand_key}`;
};
export const getUserBasePath = (username: string): string => `/${username}`;

export const getUserPath = (username: string, path: string): string =>
  `${getUserBasePath(username)}${path}`;

export const isPathActive = (location: string, path: string, exact: boolean = false): boolean =>
  new RegExp(`^(?:/en)?${path}${exact ? "$" : ""}`).test(location);

export const getHistoricalOrdersAffiliatePath = (affiliateAppId: string): string =>
  `${HISTORICAL_ORDERS_PATH}/${affiliateAppId}`;

export const getHistoricalOrdersWithEmailPath = (affiliateAppId: string, email: string): string =>
  `${HISTORICAL_ORDERS_PATH}/${affiliateAppId}/${email}`;

export const getHistoricalOrdersConfirmEmailPath = (
  affiliateAppId: string,
  email: string
): string => `${getHistoricalOrdersAffiliatePath(affiliateAppId)}/confirm_email/${email}`;

export const getHistoricalOrdersFetchOrdersPath = (affiliateAppId: string, email: string): string =>
  `${getHistoricalOrdersAffiliatePath(affiliateAppId)}/fetch_orders/${email}`;

export const getHistoricalOrdersNotFoundPath = (affiliateAppId: string): string =>
  `${getHistoricalOrdersAffiliatePath(affiliateAppId)}/not_found`;
