import React from "react";
import { BUTTON_SIZES, OutlineButton } from "../../elements/v2/Button/Button";
import GoogleIcon from "../../../assets/icons/google-icon.svg";
import styled from "styled-components";
import { Svg } from "../../elements/Svg/Svg";
import Events, { EmailCaptureConnectStartedFrom } from "../../../utils/analytics";
import { ImportGmailOrdersProps } from "./ImportGmailOrders";

type Props = {
  from: EmailCaptureConnectStartedFrom;
  gmailOrderSyncUrl: ImportGmailOrdersProps["gmailOrderSyncUrl"];
  currentUser?: ImportGmailOrdersProps["currentUser"];
  $transparent?: boolean;
};

export default function GmailConnectButton({
  from,
  gmailOrderSyncUrl,
  currentUser,
  $transparent = false,
}: Props) {
  const handleClickConnect = () => {
    Events.sendEmailCaptureConnectStarted(from, {
      user: currentUser,
    });

    window.location.href = gmailOrderSyncUrl;
  };

  return (
    <GoogleWrapperButton onClick={handleClickConnect} $transparent={$transparent}>
      <SvgStyled content={GoogleIcon} />
      <span id="google-button-text">Connect Gmail Account →</span>
    </GoogleWrapperButton>
  );
}

const SvgStyled = styled(Svg)`
  align-self: center;
  height: 40px;
  width: 40px;
`;

const GoogleWrapperButton = styled(OutlineButton).attrs({
  size: BUTTON_SIZES.MEDIUM,
})<{ $transparent: boolean }>`
  justify-content: center;
  width: 100%;
  height: 48px;
  border-radius: 4px;
  color: #222;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.25px;
  text-transform: none;
  ${({ $transparent }) =>
    $transparent ? `background-color: transparent;` : `background-color: white;`}
  font-size: 16px;
  font-weight: 500;
  #google-button-text {
    padding-top: 2px;
  }
`;
