import React, { useState } from "react";
import styled from "styled-components";
import { Svg } from "../../elements/Svg/Svg";
import NewIcon from "../../../assets/images/gmail/new.svg";
import { A, Span } from "../../elements/v2/Typography/Typography";
import { default as DefaultFlexbox } from "../../elements/v2/Box/FlexBox";
import BannerImage from "../../../assets/images/gmail/banner_image.png";
import { useMobileMediaQuery } from "../../../utils/useMediaQueries";
import { EmailCaptureConnectStartedFrom } from "../../../utils/analytics";
import GmailHowItWorksModal, { GmailHowItWorksModalProps } from "../Modals/GmailHowItWorksModal";
import GmailConnectButton from "./GmailConnectButton";

export interface ImportGmailOrdersProps {
  showGmailImportBanner: boolean;
  showGmailImportModal: boolean;
  gmailOrderSyncUrl: string;
  currentUser: {
    id: string;
    uuid: string;
    gmailFeatureEnabled: boolean;
  };
}

export default function ImportGmailOrders({
  gmailOrderSyncUrl,
  currentUser,
}: ImportGmailOrdersProps) {
  if (!currentUser.gmailFeatureEnabled) return null;

  const { isMobile } = useMobileMediaQuery({ breakpoint: 1221 });

  const [showHowItWorks, setShowHowItWorks] = useState(false);
  const handleHowItWorks = () => {
    setShowHowItWorks(true);
  };
  const onHowItWorksClose = () => {
    setShowHowItWorks(false);
  };

  const containerProps = {
    isMobile,
    modalProps: {
      open: showHowItWorks,
      onClose: onHowItWorksClose,
      from: EmailCaptureConnectStartedFrom.BANNER_HOW_IT_WORKS,
      gmailOrderSyncUrl,
      currentUser,
    },
  };

  return (
    <Container {...containerProps}>
      <GapBox $gap={isMobile ? 12 : 8}>
        <TitleText $isMobile={isMobile}>
          {isMobile && <Svg content={NewIcon} />} Sync your past purchases
        </TitleText>
        <Text $isMobile={isMobile}>
          Instantly import all your past email purchases to see how much you could earn
        </Text>
      </GapBox>
      {!isMobile && <img src={BannerImage} alt={"banner image"} height={134} />}
      <GapBox $gap={10}>
        <GmailConnectButton
          from={EmailCaptureConnectStartedFrom.BANNER}
          gmailOrderSyncUrl={gmailOrderSyncUrl}
          currentUser={currentUser}
          $transparent
        />
        <CenteredLinkText $underline={true} onClick={handleHowItWorks} textAlign={"center"}>
          How it works
        </CenteredLinkText>
      </GapBox>
    </Container>
  );
}

export const Container = ({
  success,
  isMobile,
  modalProps,
  children,
}: {
  success?: boolean;
  isMobile: boolean;
  modalProps?: GmailHowItWorksModalProps;
  children: React.ReactNode;
}) => (
  <Wrapper p={isMobile ? 1.25 : 0}>
    <GmailFlexBox $success={success} $isMobile={isMobile}>
      {modalProps && <GmailHowItWorksModal {...modalProps} />}
      {children}
    </GmailFlexBox>
  </Wrapper>
);

const GmailFlexBox = styled(DefaultFlexbox)<{
  $success?: boolean;
  $isMobile?: boolean;
}>`
  ${({ $isMobile }) =>
    $isMobile
      ? `
  border-radius: 15px;
  padding: 14px 50px 25px 50px;
  justify-content: center;
  gap: 10px;
  flex-direction: column;
  `
      : `
  padding: 0 80px;
  gap: 20px;
  justify-content: space-between;
  `}
  background-color: #fcebe5;
  ${({ $success }) => {
    if ($success)
      return `
  background-color: #DEF0FF;
  padding: 18px 24px;
  `;
    if ($success == false)
      return `
    padding: 18px 24px;
  `;
  }}
  width: 100%;
  align-items: center;
`;

const TitleText = styled(Span)<{ $isMobile?: boolean }>`
  font-size: ${({ $isMobile }) => ($isMobile ? 35 : 42)}px;
  text-align: center;
  font-weight: 400;
  font-family: "Lora", serif;
  line-height: 110%;
`;

const Text = styled(Span)<{ bold?: boolean; $isMobile?: boolean }>`
  ${({ $isMobile }) =>
    $isMobile
      ? `
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  margin-left: 0;
  padding: 0 10px;
  `
      : `
  font-size: 12px;
  line-height: 20px;
  text-align: left;
  margin-left: 6px;
  `}
  ${({ $bold }) => $bold && `font-weight: 700;`}
`;

const CenteredLinkText = styled(A)`
  text-align: center;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
`;

export const GapBox = styled(DefaultFlexbox).attrs({ flexDirection: "column" })<{ $gap: number }>`
  gap: ${({ $gap }) => `${$gap}px`};
`;

const Wrapper = styled(DefaultFlexbox)`
  background-color: white;
`;
